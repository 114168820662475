







































































































































































































































































































































































.logs2 {
  .el-tabs__header {
    margin: 0;
  }
}
.table_h5 {
  td {
    border: 1px solid #ccc;
  }
}
